import uuid from 'uuid/v4';
import type { MutableRefObject } from 'react';

import { getLogger } from '@confluence/logger';
import type { ReattachComment } from '@confluence/comment-context';

import { convertSelectionToLegacyFormat } from './convertSelectionToLegacyFormat';

const logger = getLogger('inline-comment');

export const getLegacyConvertedSelectionObj = ({
	content,
	contentId,
	commentToReattach,
	actions,
}: {
	content: MutableRefObject<HTMLDivElement | null>;
	contentId: string;
	commentToReattach: ReattachComment | null;
	actions?: any;
}) => {
	let selectionObj = {
		numMatches: 0,
		matchIndex: -1,
		selectedText: '',
		timestamp: 0,
	};

	const selection = window.getSelection();

	if (selection) {
		const range = selection.getRangeAt(0);
		const legacySelection = convertSelectionToLegacyFormat(content.current, range, contentId);

		const {
			searchText: { numMatches, index: matchIndex, selectedText },
		} = legacySelection;

		selectionObj = {
			numMatches,
			matchIndex,
			selectedText,
			timestamp: Date.now(),
		};

		/**
		 * We generate the annotation step for the upcoming new collab service
		 * to consume. This means we will eventually started sending markerRef
		 * ids from the FE.
		 */
		try {
			const markerRef = commentToReattach ? commentToReattach.inlineMarkerRef : uuid();

			if (actions) {
				const proseMirrorStep = actions.annotate(range, markerRef, 'inlineComment');

				const {
					step: { from, to, mark },
				} = proseMirrorStep;

				selectionObj['step'] = {
					from,
					to,
					mark: {
						type: 'annotation',
						attrs: {
							key: mark.attrs?.annotationType,
							value: mark.attrs?.id,
						},
					},
				};
			}
		} catch (e) {
			logger.error`An error occurred when generating a step - ${e}`;
		}
	}

	return selectionObj;
};
